import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../components/layout'
import PrivacyPolicyComponent from '../components/PrivacyPolicy/PrivacyPolicy'
import Helmet from '../components/helmet'
import Footer from '../components/Footer/Footer'
import EarlyAcess from '../components/EarlyAcess/EarlyAcess'

const PrivacyPolicy = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet
        lang={intl.locale}
        pathName={`${intl.locale}/privacy-policy`}
        title={intl.formatMessage({ id: 'meta.privacy.title' })}
        description={intl.formatMessage({ id: 'meta.privacy.description' })}
      />
      <PrivacyPolicyComponent image={data.image} />
      <EarlyAcess />
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query PrivacyPolicyImage {
    image: file(relativePath: { eq: "privacy-policy.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
