/* eslint-disable react/display-name */
import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import EnglishPolicy from './EnglishPolicy'
import GermanPolicy from './GermanPolicy'
import styles from './PrivacyPolicy.module.scss'

const PrivacyPolicy = ({ image }) => {
  const intl = useIntl()
  return (
    <div className={styles.root}>
      <BackgroundImage
        Tag="div"
        className={styles.banner}
        fluid={image.childImageSharp.fluid}
      ></BackgroundImage>
      <div className={`container ${styles.section}`}>
        <div className={styles.card}>
          <div className={styles.header}>
            <h1>
              <FormattedMessage id="privacyPolicy.headerTitle" />
            </h1>
          </div>
          <div className={styles.content}>
            {intl.locale === 'en' && <EnglishPolicy />}
            {intl.locale === 'de' && <GermanPolicy />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
