/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/display-name */
import React from 'react'

const GermanPolicy = () => {
  return (
    <>
      <h3>
        Centralsoft GmbH hält sich an hohe Datenschutz-Standards bei der
        Erhebung und Verarbeitung personenbezogener Daten. Wir sammeln,
        speichern und verarbeiten Ihre personenbezogenen Daten nur nach dem
        Schweizerischen Bundesgesetz über den Datenschutz (DSG), der
        Europäischen Allgemeinen Datenschutzrichtlinie (DSGVO) und den
        nachstehend aufgeführten Richtlinien.
      </h3>
      <p>
        Diese Datenschutzrichtlinie erklärt, welche Informationen Centralsoft
        und die damit verbundenen Dienste über ihre Benutzer sammelt und was wir
        mit diesen Informationen machen. Diese Datenschutzrichtlinie bezieht auf
        die Informationen, die wir durch Ihre Nutzung unserer Dienste bekommen
        oder wenn Sie anderweitig mit Centralsoft GmbH interagieren.
      </p>
      <p>
        "Personendaten" sind alle Einzelangaben über persönliche oder sachliche
        Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. Das
        bedeutet, es handelt sich dabei um Informationen, die Ihnen persönlich
        zugeordnet werden können und etwas über Sie aussagen können. In diesen
        Datenschutzbestimmungen wird hierfür auch die Formulierung „Ihre Daten“
        verwendet. Als "Bearbeitung" gilt dabei jeder Umgang mit Ihren Daten, so
        insbesondere auch die Sammlung, Speicherung, Verwaltung, Nutzung,
        Übermittlung, Bekanntgabe oder Löschung Ihrer Daten.
      </p>
      <p>
        Bitte beachten Sie, dass die nachfolgenden Ausführungen von Zeit zu Zeit
        angepasst werden können. Wir empfehlen Ihnen deshalb, diese
        Datenschutzerklärung regelmässig zu konsultieren. Webseiten Dritter, die
        über unsere Portale erreichbar sind, unterliegen nicht den hier
        festgehaltenen Grundsätzen. Wir übernehmen keine Verantwortung oder
        Haftung für die Beachtung des Datenschutzes durch Webseiten Dritter.
      </p>
      <h3>Welche Arten von personenbezogenen Daten erfassen wir?</h3>
      <p>
        Centralsoft GmbH wird, abhängig von Produkt oder Dienstleistung, die wir
        Ihnen zur Verfügung stellen, personenbezogene Daten über Sie erheben und
        verarbeiten:
      </p>
      <h4>Browsing-Informationen auf Webseiten</h4>
      <p>
        Wir stellen sicher, dass Ihre IP-Adresse anonymisiert wird. So können
        wir nicht feststellen woher Sie kommen. Des Weiteren ist es uns durch
        die Anonymisierung Ihrer IP-Adresse nicht möglich Ihre Websitenutzung
        mit anderen von uns gespeicherten Daten in Verbindung zu bringen.
      </p>
      <h4>Newsletter / Early Access Programm / Mailings</h4>
      <p>
        Damit wir Ihnen unsere Produkte und Dienstleistungen anbieten können,
        benötigen wir Ihre E-Mail Adresse. Manchmal haben Sie die Option uns
        Ihren Namen mitzuteilen. Ihr Name hilft uns Sie persönlich zu
        adressieren.
      </p>
      <h3>Cookies - Nutzung unserer Webseiten und Erhalt von E-Mails</h3>
      <p>
        Falls Ihr Browser sogenannte «Cookies» unterstützt und zulässt, können
        wir das Erstellen und Ablegen dieser auslösen, um das bestmögliche
        Funktionieren und eine konitnuierliche Weiterentwicklung unserer
        Webseiten zu unterstützen. Cookies helfen, Ihren Besuch auf unseren
        Webseiten einfacher, angenehmer und sinnvoller zu gestalten. Cookies
        sind Informationsdateien, die Ihr Webbrowser automatisch auf der
        Festplatte Ihres Computers speichert, wenn Sie unsere Internetseite
        besuchen. Cookies beschädigen weder die Festplatte Ihres Rechners noch
        werden von Cookies persönliche Daten der Anwender an uns übermittelt.
      </p>
      <p>
        Sie können die Verwendung von Cookies ablehnen, indem Sie die
        entsprechenden Einstellungen in Ihrem Browser vornehmen. Beachten Sie
        jedoch, dass dies Ihre Erfahrung mit unseren Webseiten beeinträchtigen
        kann. Weitere Informationen zu Cookies, einschliesslich der Verwaltung,
        Ablehnung und Löschung von Cookies, finden Sie unter{' '}
        <a
          href="https://www.cookiesandyou.com"
          target="_blank"
          rel="noreferrer"
        >
          https://www.cookiesandyou.com
        </a>{' '}
        and{' '}
        <a
          href="https://www.allaboutcookies.org"
          target="_blank"
          rel="noreferrer"
        >
          https://www.allaboutcookies.org
        </a>
        .
      </p>
      <p>
        Unsere Webseiten nutzen Google Analytics, einem Dienst von Google Inc.,
        der darüber berichtet auf welche Weise unsere Webseiten genutzt werden.
        Google Analytics platziert dazu kleine Textdateien («Cookies») auf Ihrem
        Computer oder anderen Geräten. Die Cookies sammeln Informationen über
        die Anzahl der Besucher auf den Webseiten, die effektiv besuchten Seiten
        und die auf den Webseiten verbrachte Zeit. Diese Informationen sind
        zusammengefasst und nicht persönlich identifizierbar. Wenn Sie Fragen
        oder Bedenken bezüglich der Nutzung von Google Analytics haben, können
        Sie Google Analytics blockieren, indem Sie ein Plug-in in Ihrem Browser
        installieren. Ein Plug-in für die gängigsten Browser kann hier
        heruntergeladen werden:{' '}
        <a href="https://tools.google.com" target="_blank" rel="noreferrer">
          https://tools.google.com
        </a>
        .
      </p>
      <p>
        Unsere Webseiten und Produkte nutzen zudem Helpcrunch, einen von
        HelpCrunch Corporation bereitgestellten Dienst, der ein Live-Chat in der
        unteren rechten Ecke einer Seite bereitstellt, damit man mit uns in
        Kontakt treten kann. Helpcrunch platziert ein Cookie in Ihrem Browser.
        Sie können die Nutzung von Helpcrunch auf dieselbe Weise wie Google
        Analytics blockieren.
      </p>
      <p>
        Wenn Sie E-Mails von Centralsoft erhalten möchten, benötigen wir von
        Ihnen Ihre E-Mail-Adresse sowie die Bestätigung, dass Sie mit dem
        Empfang von E-Mails einverstanden sind. Gleiches gilt für unsere Early
        Access Programme. Wir können Ihre E-Mail-Adresse verwenden, um Ihnen
        Benachrichtigungen, Aktualisierungen und andere Informationen per E-Mail
        zuzusenden. Wenn Sie Mitteilungen von uns erhalten und dies nicht mehr
        wünschen, können Sie sich jederzeit abmelden, indem Sie dem Abmeldelink
        in den E-Mails folgen oder uns direkt kontaktieren. Beachten Sie, dass
        wir automatisierte E-Mails und Kampagnen über Helpcrunch senden könnten.
        Helpcrunch ist unter Umständen dazu in der Lage personenbezogene Daten
        zu verwenden, um zu verstehen und uns mitzuteilen, ob Sie unsere E-Mails
        lesen oder auf die darin enthaltenen Links klicken, siehe{' '}
        <a
          href="https://helpcrunch.com/email-automation.html"
          target="_blank"
          rel="noreferrer"
        >
          https://helpcrunch.com/email-automation.html
        </a>
        .
      </p>
      <h3>Wie lange bewahren wir Ihre persönlichen Daten auf?</h3>
      <p>
        Wir bewahren personenbezogene Daten nur so lange auf, wie es zur
        Erfüllung des Zwecks, für den sie erhoben wurden, oder zur Erfüllung
        gesetzlicher oder behördlicher Auflagen erforderlich ist.
      </p>
      <h3>Wie schützen wir Ihre persönlichen Daten?</h3>
      <p>
        Wir haben geeignete technische und organisatorische Massnahmen
        getroffen, um den unbefugten oder unrechtmäßigen Zugriff auf die von
        Ihnen zur Verfügung gestellten personenbezogenen Daten zu verhindern. Da
        bei der Kommunikation per E-Mail, Instant Messaging und ähnlichen
        Kommunikationsmitteln die vollständige Datensicherheit nicht
        gewährleistet werden kann, empfehlen wir, besonders vertrauliche
        Informationen auf einem anderen sicheren Weg zu übermitteln.
      </p>
      <h3>Weitergabe von Daten an Dritte</h3>
      <p>
        In Ausnahmefällen werden Ihre persönlichen Daten an Dritte
        weitergegeben, wenn erforderlich, damit wir Ihnen unsere Produkte und
        Dienstleistungen intakt anbieten können. Wir ergreifen Maßnahmen, um
        sicherzustellen, dass sie unsere Vertraulichkeits- und
        Datensicherheitsstandards erfüllen, damit Ihre persönlichen Daten sicher
        bleiben.
      </p>
      <p>
        Wir können personenbezogene Daten an Behörden, Aufsichtsbehörden oder
        Regierungsstellen weitergeben, wenn dies gesetzlich vorgeschrieben ist
        oder wenn eine Behörde oder Regierungsstelle eine Anordnung erlassen
        hat, die solche personenbezogenen Daten von uns anfordert. Bevor wir die
        Informationen zur Verfügung stellen, werden wir Sie informieren, wenn es
        das geltende Recht erlaubt.
      </p>
      <p>
        Wir speichern und verarbeiten Ihre persönlichen Daten auf Servern in der
        Schweiz und dem Europäischen Wirtschaftsraum (EWR). Im Normalfall
        übertragen wir Ihre persönlichen Daten nicht in Länder ausserhalb der
        Schweiz und der EWR. Sollte es im Spezialfall notwendig sein, übertragen
        wir ihre Daten in Länder ausserhalb der Schweiz und der EWR (i) nur nach
        Ihrer Einwilligung, (ii) wo das Land einen angemessenen Schutz Ihrer
        persönlichen Daten gewährleistet, (iii) wo wir entsprechende
        Schutzvorkehrungen getroffen haben, um Ihre persönlichen Daten zu
        schützen, (iv) oder wo die Übertragung nach geltendem Recht zulässig
        ist.
      </p>
      <p>
        Wenn Sie mehr über die Sicherheitsvorkehrungen erfahren möchten, die wir
        für die Übermittlung Ihrer persönlichen Daten getroffen haben,
        kontaktieren Sie uns bitte unter{' '}
        <a href="mailto:contact@centralsoft.io">contact@centralsoft.io</a>.
      </p>
      <h3>Rechtsgrundlagen</h3>
      <p>
        Wir bearbeiten Ihre Personendaten nur im Rahmen der
        Datenbearbeitungsgrundsätze und wenn eine Rechtsgrundlage vorliegt. Wo
        es zur Anbahnung und Abwicklung unseres Vertrages dient, greift diese
        Rechtsgrundlage. Ansonsten haben wir ein Interesse unsere Angebote
        kontinuierlich zu verbessern und Ihren Bedürfnissen anzupassen. Dies
        stellt eine Notwendigkeit dar um unsere Angebote weiterzuentwickeln,
        finanzieren zu können und um die Sicherheit unserer Angebote zu
        gewährleisten. Wir gehen davon aus, dass unsere Interessen überwiegen.
        Falls Sie in eine Datenbearbeitung eingewilligt haben, gilt diese.
      </p>
      <hr></hr>
      <h3>Ihre Datenschutzrechte</h3>
      <p>Sie haben das Recht dazu:</p>
      <ul>
        <li>
          auf Ihre persönlichen Daten zuzugreifen und bestimmte Informationen
          darüber zu erhalten, wie z.B. den Zweck, zu dem sie verarbeitet
          werden, die Personen, an die sie weitergegeben werden, und den
          Zeitraum, für den sie gespeichert werden.
        </li>
        <li>
          verlangen, dass wir Ungenauigkeiten in Ihren persönlichen Daten
          unverzüglich korrigieren.
        </li>
        <li>verlangen, dass wir Ihre persönlichen Daten löschen.</li>
        <li>
          verlangen, dass wir die Verarbeitung Ihrer persönlichen Daten
          einschränken.
        </li>
        <li>
          die persönlichen Daten, die Sie uns zur Verfügung gestellt haben, in
          maschinenlesbarer Form zu erhalten.
        </li>
      </ul>
      <p>
        Bitte beachten Sie, dass diese Rechte nicht absolut sind und bestimmten
        Ausnahmen nach geltendem Datenschutzrecht unterliegen.
      </p>
      <p>
        Wenn Sie Fragen haben oder von Ihren Rechten Gebrauch machen möchten,
        wenden Sie sich bitte an uns über folgende Adresse:{' '}
        <a href="mailto:contact@centralsoft.io">contact@centralsoft.io</a>.
      </p>
      <p>
        Wenn Sie mit der Antwort von Centralsoft GmbH nicht zufrieden sind,
        haben Sie das Recht, eine Beschwerde bei der Datenschutzbehörde des
        Landes einzureichen, in dem Sie wohnen oder arbeiten oder in dem Sie
        glauben, dass ein Problem im Zusammenhang mit Ihren Daten aufgetreten
        ist.
      </p>
      <hr></hr>
      <h3>
        Für die Zwecke dieser Datenschutzerklärung ist der Inhaber Ihrer
        persönlichen Daten
      </h3>
      <p>
        Centralsoft GmbH<br></br>
        CHE-160.912.626<br></br>
        Hauptstrasse 54<br></br>
        6045 Meggen<br></br>
        Luzern, Switzerland<br></br>
        <br></br>T <a href="tel:+41415112708">+41 41 511 27 08</a>
        <br></br>
        <a href="mailto:contact@centralsoft.io">contact@centralsoft.io</a>
        <br></br>
        <a href="https://centralsoft.io" target="_blank" rel="noreferrer">
          https://centralsoft.io
        </a>
      </p>
      <hr></hr>
      <h3>Kontakt Datenschutz</h3>
      <p>
        Wenn Sie Fragen zum Datenschutz auf unserer Webseite haben, Auskünfte
        verlangen möchten oder die Löschung Ihrer Daten beantragen möchten,
        wenden Sie sich bitte an unseren Ansprechpartner für Datenschutzrecht,
        in dem Sie eine E-Mail an{' '}
        <a href="mailto:privacy@centralsoft.io">privacy@centralsoft.io</a>{' '}
        schicken.
      </p>
      <p>Die Kontaktdaten unseres Datenschutzbeauftragten lauten wie folgt:</p>
      <p>
        Centralsoft GmbH<br></br>
        Datenschutzbeauftragter<br></br>
        Hauptstrasse 54<br></br>
        6045 Meggen<br></br>
        Lucerne, Switzerland<br></br>
      </p>
    </>
  )
}

export default GermanPolicy
