/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/display-name */
import React from 'react'

const EnglishPolicy = () => {
  return (
    <>
      <h3>
        Centralsoft GmbH complies to high standards when it comes to protection
        of collected and processed personal data. We do only collect, store and
        process your personal data in accordance with the Swiss Federal Act on
        Data Protection (DSG), the European General Data Protection Directive
        (GDPR) and the principles set forth below.
      </h3>
      <p>
        This privacy policy explains what information Centralsoft and its
        related products collect about its users and what we do with that
        information. This privacy statement applies to the information that we
        obtain through your use of our services via a device or when you
        otherwise interact with Centralsoft.
      </p>
      <p>
        "Personal data" means any particulars relating to the personal or
        material circumstances of an identified or identifiable natural person.
        This means that it is information that can be personally attributed to
        you and can say something about you. In these data protection
        provisions, the phrase "your data" is also used for this purpose.
        "Processing" means any handling of your data, including in particular
        the collection, storage, administration, use, transmission, disclosure
        or deletion of your data.
      </p>
      <p>
        Please note that we may amend this privacy policy from time to time. We
        therefore recommend that you consult this privacy policy regularly.
        Third-party websites that can be accessed via our portals are not
        subject to the principles set out here. We assume no responsibility or
        liability for the observance of data protection by third-party websites.
      </p>
      <h3>What Kind of Personal Data Do We Collect?</h3>
      <p>
        Centralsoft GmbH will, based on the product or service we provide you
        with, collect and process personal data about you:
      </p>
      <h4>Browsing Information</h4>
      <p>
        We make sure that your IP address is anonymized, which means that we
        cannot detect where you come from or associate your browsing behaviour
        with any other data held by us.
      </p>
      <h4>Newsletter / Early Access Program / Mailings</h4>
      <p>
        In order to be able to provide you with our products and services, we
        need your e-mail address. Sometimes, you have the option to share more
        information with us such as your first and last name. This helps us to
        personally address you in our mailings and messages.
      </p>
      <h3>Cookies - Using Our Websites and Receiving E-Mails</h3>
      <p>
        Given that your Browser allows the placement of so called «Cookies», we
        may issue the creation and storage of them in your browser in order to
        facilitate the functioning and continuous improvement of our websites.
        Cookies help make your visit to our websites easier, more pleasant and
        more effective. Cookies are information files that your web browser
        automatically stores on your computer's hard drive when you visit our
        website. Cookies will not damage your computer’s hard drive, nor will
        users’ personal data be sent from these cookies to us.
      </p>
      <p>
        You may refuse the use of cookies by selecting the appropriate settings
        on your browser. Note however that this may affect your experience of
        our websites. To find out more about cookies, including how to manage,
        reject and delete cookies, visit{' '}
        <a
          href="https://www.cookiesandyou.com"
          target="_blank"
          rel="noreferrer"
        >
          https://www.cookiesandyou.com
        </a>{' '}
        and{' '}
        <a
          href="https://www.allaboutcookies.org"
          target="_blank"
          rel="noreferrer"
        >
          https://www.allaboutcookies.org
        </a>
        .
      </p>
      <p>
        Our websites use Google Analytics, a service provided by Google Inc.
        that tracks and reports on the manner in which our websites are used.
        Google Analytics does this by placing small text files called «cookies»
        on your computer or other device. Cookies collect information about the
        number of visitors to the websites, the pages visited and the time spent
        on the websites. This information is aggregated and not personally
        identifiable. In case you have any questions or concerns with the use of
        Google Analytics, you can block Google Analytics by installing a plug-in
        into your Browser. A plug-in for the most common Browsers can be
        downloaded following this link:{' '}
        <a href="https://tools.google.com" target="_blank" rel="noreferrer">
          https://tools.google.com
        </a>
        .
      </p>
      <p>
        We also use Helpcrunch on our websites and products, a service provided
        by the HelpCrunch Corporation, that provides a chat window at the bottom
        right corner of the pages. It allows you to get into contact with us.
        Helpcrunch sets a cookie in your Browser. You are free to block the use
        of Helpcrunch using the same way as metioned for Google Analytics above.
      </p>
      <p>
        In case you would like to receive e-mails from Centralsoft, we require
        your e-mail address and confirmation that you agree to receive the
        mailings. Same applies for entering Early Access Programs. We may use
        your e-mail address to send you alerts, updates and other information by
        e-mail. If you receive communications from us and no longer wish to do
        so, you may unsubscribe at any time by following the unsubscribe-link
        included in these e-mails. You can always contact us in case you wish to
        no longer receive mails from us. We may use Helpcrunch to send automated
        emails and campaigns. Helpcrunch might track whether you open the
        mailings and which links you follow, see{' '}
        <a
          href="https://helpcrunch.com/email-automation.html"
          target="_blank"
          rel="noreferrer"
        >
          https://helpcrunch.com/email-automation.html
        </a>
        .
      </p>
      <h3>How Long Do We Store Your Personal Data?</h3>
      <p>
        We only store personal data for as long as it is necessary to fulfil the
        purpose for which it was collected or to comply with legal or official
        requirements.
      </p>
      <h3>How Do We Keep Your Personal Data Secure?</h3>
      <p>
        We have appropriate technical and organisational measures in place to
        prevent unauthorised or unlawful access to the personal data you have
        provided to us. As complete data security cannot be guaranteed for
        communication via e-mails, instant messaging, and similar means of
        communication, we would recommend sending any particularly confidential
        information by an alternative secure means.
      </p>
      <h3>Disclosure of Data to Third Parties</h3>
      <p>
        Only in exceptional cases we share your personal data with third parties
        if it is necessary for us to be able to provide you with our products
        and services. We take measures to ensure they meet our data security
        standards, so that your personal data remains secure.
      </p>
      <p>
        We may disclose personal data to public authorities, regulators or
        governmental bodies when required by law or regulation or if an
        authority or governmental body has issued an order requesting such
        personal data from us. Before we provide the information, we will inform
        you if the applicable law allows us to do so.
      </p>
      <p>
        We store and process your personal data on servers located within
        Switzerland and the European Economic Area (the «EEA»). Normally we do
        not transfer your personal data outside of Switzerland and/or the EEA.
        If special cases require a transfer of personal data outside of
        Switzerland and/or the EEA, we only do so where (i) you have given us
        your consent, (ii) the country ensures an adequate level of protection
        for your personal data, (iii) we have put appropriate safeguards in
        place to protect your personal data or (iv) the transfer is permitted by
        applicable laws.
      </p>
      <p>
        In case you would like to learn more about the safeguards that we have
        in place to govern the transfer of your personal data you can contact us
        at <a href="mailto:contact@centralsoft.io">contact@centralsoft.io</a>.
      </p>
      <h3>Legal Priciples</h3>
      <p>
        We process your personal data only within the framework of the data
        processing principles and if there is a legal basis. We always process
        your personal data for a specific purpose and only process the personal
        data relevant to achieve that purpose. Where it serves to initiate and
        execute our contract, this legal basis applies. Otherwise, we have an
        interest in continuously improving our offers and adapting them to your
        needs. This is a necessity in order to further develop, finance and
        guarantee the security of our products and offers. We assume that our
        interests prevail. This applies if you have consented to data
        processing.
      </p>
      <hr></hr>
      <h3>Your Privacy Rights</h3>
      <p>You have the right to:</p>
      <ul>
        <li>
          access your personal data and to be provided with certain information
          in relation to it, such as the purpose for which it is processed, the
          persons to whom it is disclosed and the period for which it will be
          stored.
        </li>
        <li>
          require us to correct any inaccuracies in your personal data without
          undue delay.
        </li>
        <li>require us to erase your personal data.</li>
        <li>require us to restrict processing of your personal data.</li>
        <li>
          receive the personal data which you have provided to us, in a machine
          readable format.
        </li>
      </ul>
      <p>
        Please note that these rights are not absolute and are subject to
        certain exemptions under applicable data protection law.
      </p>
      <p>
        In case you have further questions or would like to make use of any of
        your rights please contact{' '}
        <a href="mailto:contact@centralsoft.io">contact@centralsoft.io</a>.
      </p>
      <p>
        If you are not satisfied with Centralsoft GmbH’s response, you have the
        right to make a complaint to the data protection authority in the
        jurisdiction where you live or work, or in the place where you believe
        an issue in relation to your data has arisen.
      </p>
      <hr></hr>
      <h3>
        For the Purpose of This Privacy Policy the Controller of Your Personal
        Data Is
      </h3>
      <p>
        Centralsoft GmbH<br></br>
        CHE-160.912.626<br></br>
        Hauptstrasse 54<br></br>
        6045 Meggen<br></br>
        Lucerne, Switzerland<br></br>
        <br></br>T <a href="tel:+41415112708">+41 41 511 27 08</a>
        <br></br>
        <a href="mailto:contact@centralsoft.io">contact@centralsoft.io</a>
        <br></br>
        <a href="https://centralsoft.io" target="_blank" rel="noreferrer">
          https://centralsoft.io
        </a>
      </p>
      <hr></hr>
      <h3>Contact Person</h3>
      <p>
        If you have any questions regarding data protection on our website,
        would like to request information or request the deletion of your data,
        please contact our contact person for data protection law by sending an
        email to{' '}
        <a href="mailto:privacy@centralsoft.io">privacy@centralsoft.io</a>.
      </p>
      <p>The contact details of our data protection officer are as follows:</p>
      <p>
        Centralsoft GmbH<br></br>
        Data Protection Officer<br></br>
        Hauptstrasse 54<br></br>
        6045 Meggen<br></br>
        Lucerne, Switzerland<br></br>
      </p>
    </>
  )
}

export default EnglishPolicy
